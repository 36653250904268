/* UNMODIFIED */
import { Activity, IActivity } from "./Activity";
import { ICompany } from "./Company";
import { IAppointmentType } from "./AppointmentType";
import { IAppointmentAnnotation } from "./AppointmentAnnotation";
import { ExchangeSynchronizationStatus } from "./ExchangeSynchronizationStatus";
import { IContact } from "./Contact";

export interface IAppointment extends IActivity {
	company: ICompany | null;
    company_Id: string | null;
    isFollowUp: boolean;
    appointmentType: IAppointmentType | null;
    appointmentType_Id: string | null;
    isClosed: boolean;
    closeDate: Date | null;
    annotations: IAppointmentAnnotation[] | null;
    weeksDueCorrection: number;
    exchangeSynchronizationStatus: ExchangeSynchronizationStatus | null;
    contactWith: IContact | null;
    contactWith_Id: string | null;
    exchangeSynchronizationStatusName: string | null;
}

export class Appointment extends Activity implements IAppointment {
	public company: ICompany | null = null;
    public company_Id: string | null = null;
    public isFollowUp: boolean = false;
    public appointmentType: IAppointmentType | null = null;
    public appointmentType_Id: string | null = null;
    public isClosed: boolean = false;
    public closeDate: Date | null = null;
    public annotations: IAppointmentAnnotation[] | null = null;
    public weeksDueCorrection: number = 0;
    public exchangeSynchronizationStatus: ExchangeSynchronizationStatus | null = null;
    public contactWith: IContact | null = null;
    public contactWith_Id: string | null = null;
    public exchangeSynchronizationStatusName: string | null = null;
}
