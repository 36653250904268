import { Fragment, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICollectionViewModel } from '../../viewmodels/Collections/_collectionViewModel.interfaces';
import { EntityDetailPopupConfigured } from '../EntityDetailPopup/EntityDetailPopup';
import { EntityType } from '../EntityDetailPopup/entityDetailPopup.enums';
import { AsyncLock } from '../../utilities/AsyncLock';
import { IconCustomType } from '../IconCustom/IconCustom.definitions';
import DashboardList, { IDashboardListMethods } from './DashboardList';
import { EditMethod } from '../EntityDetailPopup/entityDetailPopup.interfaces';
import { useAppSelector } from '../../store/hooks';

export interface IDashboardLaneProps {
	icon: IconCustomType;
	title: string;
	cvm: ICollectionViewModel<any, any> | any;
	elementGeneratorFunc: any;
	popupEntityType: EntityType;
	disableAddButton?: boolean;
	agenda?: boolean;
	hideSearch?: boolean;
	showCalendar?: boolean;
}

export function DashboardLane(props: IDashboardLaneProps): JSX.Element {
	const refHomeList = useRef<IDashboardListMethods | null>(null);
	const lock = useMemo(() => new AsyncLock(), []);

	const [elements, setElements] = useState<ReactElement[]>([]);
	const [currentId, setCurrentId] = useState<string>();
	const [popupMethod, setPopupMethod] = useState<EditMethod>(EditMethod.update);

	const currentRepresentative = useAppSelector((store) => store.currentRepresentative.currentRepresentative);

	//Generates the list items

	//Called after popup close
	const closeFunction = useCallback(() => {
		setCurrentId(undefined);
		props.cvm.read();
	}, [props.cvm]);

	//Starts spinner
	const spinStart = useCallback(() => {
		if (!refHomeList.current) return;
		refHomeList.current.spinStart();
	}, []);

	//Stops spinner
	const spinStop = useCallback(() => {
		if (!refHomeList.current) return;
		refHomeList.current.spinStop();
	}, []);

	//Executes a locked async function and shows spinner while running
	const execute = useCallback(
		async (func: () => Promise<boolean>) => {
			if (lock.isAcquired()) {
				return false;
			}
			let success = false;

			await lock.acquire(10000);

			try {
				spinStart();
				success = await func();
			} catch (error) {
				console.warn('Error in execute: ' + error);
			} finally {
				lock.release();
				spinStop();
			}

			return success;
		},
		[lock, spinStart, spinStop]
	);

	//On ListItem click
	const handle_onListItemClick = useCallback(
		async (entity: any): Promise<boolean> => {
			setPopupMethod(EditMethod.update);
			setCurrentId(entity[props.cvm.pkName]);

			return true;
		},
		[props.cvm.pkName]
	);

	//On "Plus" click
	const handle_onNew = useCallback(async (): Promise<boolean> => {
		setCurrentId('');
		setPopupMethod(EditMethod.create);

		return true;
	}, []);

	//On "PageNext" (scrollposition bottom)
	const handle_onPageNext = useCallback(async (): Promise<boolean> => {
		return await execute(props.cvm.pageNext);
	}, [props.cvm.pageNext, execute]);

	//On "Search" (searchquery entered)
	const handle_onSearch = useCallback(
		async (s: string): Promise<boolean> => {
			return await execute(async () => props.cvm.search(s));
		},
		[execute, props.cvm]
	);

	const items = props.cvm.items;
	const elementGeneratorFunc = props.elementGeneratorFunc;
	const generateElements = useCallback(() => {
		const elArray: ReactElement[] = [];

		items.forEach((item) => {
			const itemElement = elementGeneratorFunc(item, handle_onListItemClick);
			elArray.push(itemElement);
		});

		setElements(elArray);
	}, [elementGeneratorFunc, handle_onListItemClick, items]);

	//The "constructor"
	const constructor = useCallback(() => {
		if (!props.agenda) {
			execute(props.cvm.read);
		}
	}, [execute, props.cvm.read, props.agenda]);

	//Readability
	useEffect(constructor, []);
	useEffect(() => {
		if (!props.agenda) {
			props.cvm.switchRepresentative();
		}
	}, [currentRepresentative]);
	useEffect(generateElements, [props.cvm.items]);

	return (
		<Fragment>
			<DashboardList
				ref={refHomeList}
				iconLeft={props.icon}
				title={props.title}
				onNew={async () => await handle_onNew()}
				disableAddButton={props.disableAddButton}
				onPageNext={async () => await handle_onPageNext()}
				onSearch={async (s: string) => await handle_onSearch(s)}
				elements={elements}
				hideSearch={props.hideSearch}
				showCalendar={props.showCalendar}
			/>
			{currentId !== undefined && (
				<EntityDetailPopupConfigured
					id={currentId}
					editMethod={popupMethod} //TODO
					closeFunction={closeFunction}
					popupEntityType={props.popupEntityType}
					viewModelInstanceName={EntityType[props.popupEntityType]}
				/>
			)}
		</Fragment>
	);
}
