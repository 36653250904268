import { ThemeColorType } from '../../globals/enums';
import { IIconCustomDictionary } from './IconCustom.types';
import { IconCustomDefinition } from './IconCustomDefinition';

export enum IconCustomType {
	GEN_COMPANY,
	GEN_TASK,
	GEN_OPPORTUNITY,
	GEN_CONTACT,
	GEN_PLUS,
	GEN_AGENDA,
	GEN_ACTIVITY,

	DASH_ACCOUNT,
	DASH_LEAD,
	DASH_TASK,
	DASH_ACTIVITY,
	DASH_CONTACT,
	DASH_CONTACT_MAN,
	DASH_CONTACT_WOMAN,
	DASH_OPPORTUNITY,
	DASH_OPPORTUNITY_1,
	DASH_OPPORTUNITY_2,
	DASH_OPPORTUNITY_3,
	DASH_OPPORTUNITY_4,
	DASH_OPPORTUNITY_5,
	DASH_OPPORTUNITY_6,

	LIST_ACCOUNT,
	LIST_LEAD,
}

const iconGen: IconCustomDefinition = new IconCustomDefinition().sAuto(true).sConClass('gen').sColor(ThemeColorType.blisssDarkBlue).sThick(0);
const iconPlus: IconCustomDefinition = new IconCustomDefinition().sDaddy(iconGen).sConClass('plus').sImage('circle-plus').sThick(2);
const iconDesk: IconCustomDefinition = new IconCustomDefinition().sConClass('rounded_dash').sThick(1);
const iconList: IconCustomDefinition = new IconCustomDefinition().sConClass('list').sThick(2);
export const IconCustomDefinitions: IIconCustomDictionary = {
	[IconCustomType.GEN_PLUS]: new IconCustomDefinition(iconPlus),
	[IconCustomType.GEN_COMPANY]: new IconCustomDefinition(iconGen, 'city'),
	[IconCustomType.GEN_TASK]: new IconCustomDefinition(iconGen, 'list-check'),
	[IconCustomType.GEN_OPPORTUNITY]: new IconCustomDefinition(iconGen, 'dice-three'),
	[IconCustomType.GEN_CONTACT]: new IconCustomDefinition(iconGen, 'address-book'),
	[IconCustomType.GEN_AGENDA]: new IconCustomDefinition(iconGen, 'calendar-days'),
	[IconCustomType.GEN_ACTIVITY]: new IconCustomDefinition(iconGen, 'calendar-days'),

	[IconCustomType.DASH_TASK]: new IconCustomDefinition(iconDesk, 'list-check'),
	[IconCustomType.DASH_OPPORTUNITY]: new IconCustomDefinition(iconDesk, 'dice-three'),
	[IconCustomType.DASH_OPPORTUNITY_1]: new IconCustomDefinition(iconDesk, 'dice-one'),
	[IconCustomType.DASH_OPPORTUNITY_2]: new IconCustomDefinition(iconDesk, 'dice-two'),
	[IconCustomType.DASH_OPPORTUNITY_3]: new IconCustomDefinition(iconDesk, 'dice-three'),
	[IconCustomType.DASH_OPPORTUNITY_4]: new IconCustomDefinition(iconDesk, 'dice-four'),
	[IconCustomType.DASH_OPPORTUNITY_5]: new IconCustomDefinition(iconDesk, 'dice-five'),
	[IconCustomType.DASH_OPPORTUNITY_6]: new IconCustomDefinition(iconDesk, 'dice-six'),

	[IconCustomType.DASH_ACCOUNT]: new IconCustomDefinition(iconDesk, 'buildings').sFlip(true),
	[IconCustomType.DASH_LEAD]: new IconCustomDefinition(iconDesk, 'building').sCorY(6),
	[IconCustomType.DASH_CONTACT]: new IconCustomDefinition(iconDesk, 'person-half-dress'),
	[IconCustomType.DASH_CONTACT_MAN]: new IconCustomDefinition(iconDesk, 'person-simple'),
	[IconCustomType.DASH_CONTACT_WOMAN]: new IconCustomDefinition(iconDesk, 'person-dress-simple'),
	[IconCustomType.DASH_ACTIVITY]: new IconCustomDefinition(iconDesk, 'calendar-days'),

	[IconCustomType.LIST_ACCOUNT]: new IconCustomDefinition(iconList, 'buildings').sFlip(true),
	[IconCustomType.LIST_LEAD]: new IconCustomDefinition(iconList, 'building'),
};
