import { PanelMain } from '../../components';
import { DashboardLaneActivities } from '../../components/PanelDashboard/_DashboardLanes/DashboardLaneActivities';
import { DashboardLaneCompanies } from '../../components/PanelDashboard/_DashboardLanes/DashboardLaneCompanies';
import { DashboardLaneOpportunities } from '../../components/PanelDashboard/_DashboardLanes/DashboardLaneOpportunities';
import { DashboardLaneTasks } from '../../components/PanelDashboard/_DashboardLanes/DashboardLaneTasks';
import { hasModule, moduleNames } from '../../utilities/authProvider';

export const DashboardPage = () => {
	const hasOpportunityModule = hasModule(moduleNames.opportunity);
	return (
		<PanelMain>
			<DashboardLaneCompanies />
			<DashboardLaneTasks />
			{hasOpportunityModule && <DashboardLaneOpportunities />}
			<DashboardLaneActivities />
		</PanelMain>
	);
};
