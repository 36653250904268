import { SetStateAction, Dispatch } from 'react';
import { AccountQuery } from '../business/Query/AccountQuery';
import { CompanyQuery } from '../business/Query/CompanyQuery';
import { ContactQuery } from '../business/Query/ContactQuery';
import { OpportunityQuery } from '../business/Query/OpportunityQuery';
import { PrioritizedCompanyQuery } from '../business/Query/PrioritizedCompanyQuery';
import { PrioritizedOpportunityQuery } from '../business/Query/PrioritizedOpportunityQuery';
import { PrioritizedTaskQuery } from '../business/Query/PrioritizedTaskQuery';
import { RepresentativeQuery } from '../business/Query/RepresentativeQuery';
import { TaskQuery } from '../business/Query/TaskQuery';
import { PrioritizedCompanyView } from '../model/Views/PioritizedCompanyView';
import { ActivityTypeName, CompanyTypeName } from '../model/_Enums';
import { PrioritizedCompany } from '../models/BusinessObjects_Model/PrioritizedCompany';
import { CompanyView } from '../models/BusinessObjects_View/CompanyView';
import { ContactView } from '../models/BusinessObjects_View/ContactView';
import { PrioritizedOpportunityView } from '../models/BusinessObjects_View/PrioritizedOpportunityView';
import { PrioritizedTaskView } from '../models/BusinessObjects_View/PrioritizedTaskView';
import { RepresentativeView } from '../models/BusinessObjects_View/RepresentativeView';
import { Activity } from '../models/Model/Activity';
import { Address } from '../models/Model/Address';
import { Company } from '../models/Model/Company';
import { CompanyAnnotation } from '../models/Model/CompanyAnnotation';
import { CompanyGroup } from '../models/Model/CompanyGroup';
import { Contact } from '../models/Model/Contact';
import { Opportunity } from '../models/Model/Opportunity';
import { Task } from '../models/Model/Task';
import { VisitingFrequency } from '../models/Model/VisitingFrequency';
import { File } from '../models/Model/File';
import { server } from './_Executive';
import { AddressQuery } from '../business/Query/AddressQuery';
import { EntityType } from '../globals/enums';
import { BaseQuery } from '../business/Query/_BaseQuery';
import { AddressType } from '../models/Model/AddressType';
import { AddressTypeQuery } from '../business/Query/AddressTypeQuery';
import { AddressTypeView } from '../models/BusinessObjects_View/AddressTypeView';
import { LeadQuery } from '../business/Query/LeadQuery';
import { CompanyAnnotationQuery } from '../business/Query/CompanyAnnotationQuery';
import { CompanyAnnotationView } from '../models/BusinessObjects_View/CompanyAnnotationView';
import { ActivityQuery } from '../business/Query/ActivityQuery';
import { AppointmentView } from '../models/BusinessObjects_View/AppointmentView';
import { Appointment } from '../models/Model/Appointment';
import { AppointmentQuery } from '../business/Query/AppointmentQuery';
import { AgendaItemView } from '../models/BusinessObjects_View/AgendaItemView';
import { AddressView } from '../models/BusinessObjects_View/AddressView';
import { PrioritizedOpportunity } from '../models/BusinessObjects_Model/PrioritizedOpportunity';
import { PrioritizedTask } from '../models/BusinessObjects_Model/PrioritizedTask';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { FieldValue } from '../models/Model_Fields/FieldValue';
import { FieldDefinitionQuery } from '../business/Query/FieldDefinitionQuery';
import { FieldValueQuery } from '../business/Query/FieldValueQuery';
import { Representative } from '../models/Model/Representative';
import { CompanyGroupQuery } from '../business/Query/CompanyGroupQuery';
import { CompanyGroupView } from '../models/BusinessObjects_View/CompanyGroupView';
import { VisitingFrequencyQuery } from '../business/Query/VisitingFrequencyQuery';
import { VisitingFrequencyView } from '../models/BusinessObjects_View/VisitingFrequencyView';
import { OpportunityStatusReason } from '../models/Model/OpportunityStatusReason';
import { OpportunityStatusReasonQuery } from '../business/Query/OpportunityStatusReasonQuery';
import { Country } from '../models/Model/Country';
import { CountryQuery } from '../business/Query/CountryQuery';
import { CountryView } from '../models/BusinessObjects_View/CountryView';
import { AppointmentType } from '../models/Model/AppointmentType';
import { AppointmentTypeView } from '../models/BusinessObjects_View/AppointmentTypeView';
import { QuickAppointmentAnnotation } from '../models/BusinessObjects_Model/QuickAppointmentAnnotation';
import { FileQuery } from '../business/Query/FileQuery';
import { PostalCodeArea } from '../models/Model/PostalCodeArea';
import { AppointmentAnnotation } from '../models/Model/AppointmentAnnotation';
import { AppointmentAnnotationQuery } from '../business/Query/AppointmentAnnotationQuery';
import { AppointmentAnnotationView } from '../models/BusinessObjects_View/AppointmentAnnotationView';
import { PostalCodeAreaQuery } from '../business/Query/PostalCodeAreaQuery';
//TODO Opsplitsen, e.v.t iets als index.ts gebruiken zodat alles makkelijk te benaderen is
//Bij meerdere parameters, voornamelijk lists e.d., interfaces schrijven

export function findModelRow<T = object>(id: string, obj: T[]) {
	if (obj === undefined) return undefined;

	if (Array.isArray(obj)) {
		return obj.find((x) => x['id'] === id);
	}

	if (Object.entries(obj).length > 0) {
		Object.entries(obj).map((x) => {
			if (x['id'] === id) return x;
		});
	}

	return obj[id];
}

export namespace api {
	const urlCompany = 'api/Company';
	const urlActivity = 'api/Activity';
	const urlAppointment = 'api/Appointment';
	const urlPrioritizedCompany = 'api/PrioritizedCompany';
	const urlCompanyGroup = 'api/CompanyGroup';
	const urlAccount = 'api/Account';
	const urlAddress = 'api/Address';
	const urlAddressType = 'api/AddressType';
	const urlContact = 'api/Contact';
	const urlLead = 'api/Lead';
	const urlTask = 'api/Task';
	const urlValidation = 'api/ModelBaseValidation';
	const urlRepresentative = 'api/Representative';
	const urlOpportunity = 'api/Opportunity';
	const urlPrioritizedOpportunity = 'api/PrioritizedOpportunity';
	const urlPrioritizedTask = 'api/PrioritizedTask';
	const urlVisitingFrequency = 'api/VisitingFrequency';
	const urlAppointmentAnnotation = 'api/AppointmentAnnotation';
	const urlCompanyAnnotation = 'api/CompanyAnnotation';
	const urlFile = 'api/File';
	const urlFieldDefinition = 'api/FieldDefinition';
	const urlFieldValue = 'api/FieldValue';
	const urlOpportunityStatusReason = 'api/OpportunityStatusReason';
	const urlCountry = 'api/Country';
	const urlAppointmentType = 'api/AppointmentType';
	const urlQuickAnnotation = 'api/Appointment/quickAnnotation';
	const urlOrganizationSettings = '/api/OrganizationSettings/Restricted';
	const urlPostalCodeArea = '/api/PostalCodeArea';

	const incl = ['fieldValues'];

	/** FOR TESTING ONLY! */
	export function getByIdAsync<T>(e: EntityType, id: string) {
		return server.getByIdAsync<T>(`api/${e.toString()}`, id);
	}

	/** FOR TESTING ONLY! */
	export function updateAsync<T>(e: EntityType, model?: T) {
		return server.updateAsync<T>(`api/${e.toString()}`, model);
	}

	/** FOR TESTING ONLY! */
	export function deleteByIdAsync(e: EntityType, id: string) {
		return server.deleteByIdAsync(`api/${e.toString()}`, id);
	}

	/** FOR TESTING ONLY! */
	export function listAsync<T>(e: EntityType, q: BaseQuery | any) {
		return server.listAsync<T[]>(`api/${e.toString()}`, q);
	}

	/** FOR TESTING ONLY! */
	export function viewAsync<T>(e: EntityType, q: BaseQuery | any) {
		return server.viewAsync<T[]>(`api/${e.toString()}`, q);
	}

	export namespace special {
		export function updateCompany(company?: Company) {
			if (company === undefined) return undefined;

			if (company.$type === CompanyTypeName.Account) {
				return account.updateAsync(company);
			}
			if (company.$type === CompanyTypeName.Lead) {
				return lead.updateAsync(company);
			}

			return undefined;
		}

		export async function updateAppointment(model?: Appointment) {
			if (model === undefined) return undefined;

			if (model.$type === ActivityTypeName.Activity) {
				model.$type = ActivityTypeName.Appointment;
				return activity.promoteAsync(model);
			}

			if (model.$type === ActivityTypeName.Appointment) {
				return appointment.updateAsync(model as Appointment);
			}

			return undefined;
		}

		/** DEACTIVATES! */
		export function deleteCompanyByIdAsync(company?: Company) {
			if (company === undefined) return false;
			if (company['id'] === null) return false;

			const id = company['id'];

			if (company.$type === CompanyTypeName.Account) {
				return account.deleteByIdAsync(id);
			}
			if (company.$type === CompanyTypeName.Lead) {
				return lead.deleteByIdAsync(id);
			}

			return false;
		}

		/** NO OTHER METHODS */
		export function getEntityValidationsAsync() {
			return server.getAsync(urlValidation);
		}
	}

	export namespace account {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Company>(urlAccount, id, includes);
		}

		export function updateAsync(model?: Company) {
			return server.updateAsync<Company>(urlAccount, model);
		}

		export function listAsync(q?: AccountQuery | any) {
			return server.listAsync<Company[]>(urlAccount, q);
		}

		export function viewAsync(q?: AccountQuery | any) {
			return server.viewAsync<CompanyView[]>(urlAccount, q);
		}

		/** DEACTIVATES! */
		export function deleteByIdAsync(id: string) {
			return server.putByIdAsync(urlAccount, id, 'deactivate');
		}

		export async function getPriorityWeekAsync(companyId: string, representativeId?: string | null): Promise<Date | null> {
			let url = `${urlAccount}/${companyId}/PriorityWeek`;
			if (representativeId !== null && representativeId !== undefined) {
				url += `?representativeId=${representativeId}`;
			}

			const response = await server.call('GET', url);

			if (!response.ok) {
				console.warn(`getPriorityWeekAsync [${url} : ${response.statusText}`);
				return null;
			}

			const json = await response.json();
			const returnWeek = new Date(json);

			return returnWeek;
		}
	}

	export namespace lead {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Company>(urlLead, id, includes);
		}

		export function updateAsync(model?: Company) {
			return server.updateAsync<Company>(urlLead, model);
		}

		export function listAsync(q?: LeadQuery | any) {
			return server.listAsync<Company[]>(urlLead, q);
		}

		export function viewAsync(q?: LeadQuery | any) {
			return server.viewAsync<CompanyView[]>(urlLead, q);
		}

		/** DEACTIVATES! */
		export function deleteByIdAsync(id: string) {
			return server.putByIdAsync(urlLead, id, 'deactivate');
		}
	}

	export namespace activity {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Activity | Appointment>(urlActivity, id, includes);
		}

		export function updateAsync(model?: Activity) {
			return server.updateAsync<Activity>(urlActivity, model);
		}

		export function listAsync(q?: ActivityQuery | any) {
			return server.listAsync<Activity[]>(urlActivity, q);
		}

		/** WARNING: THIS FUNCTION RETURNS AGENDAITEMVIEW! */

		export function viewAgendaItemsAsync(q?: ActivityQuery | any) {
			return server.viewAsync<AgendaItemView[]>(`${urlActivity}/agenda?viewName=AgendaItemView`, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlActivity, id);
		}

		export function listAgendaAsync(q?: AccountQuery | any) {
			return server.listAsync<Activity[]>(urlActivity, q, 'agenda');
		}

		export function promoteAsync(model?: any) {
			model = server.removeNullValues(model);
			//We need to delete the id field in this case before PUT
			return server.putAsync<Appointment>(urlActivity, model as Appointment, 'promote', true);
		}
	}

	export namespace appointment {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Appointment>(urlAppointment, id, includes);
		}

		export function updateAsync(model?: Appointment) {
			return server.updateAsync<Appointment>(urlAppointment, model);
		}

		export function listAsync(q?: AppointmentQuery | any) {
			return server.listAsync<Appointment[]>(urlAppointment, q);
		}

		export function viewAsync(q?: AppointmentQuery | any) {
			return server.viewAsync<AppointmentView[]>(urlAppointment, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlAppointment, id);
		}

		export async function closeByIdAsync(id: string, returnWeek?: Date) {
			let url = `${urlAppointment}/${id}/close`;
			if (returnWeek !== undefined) {
				url += `?returnWeek=${returnWeek?.toISOString()}`;
			}

			const response = await server.call('PUT', url);

			if (!response.ok) {
				console.warn(`closeByIdAsync [${url} : ${response.statusText}]`);
				return false;
			}

			return response.status === 200;
		}
	}

	export namespace appointmentAnnotation {
		export function viewAsync(q?: AppointmentAnnotationQuery) {
			return server.viewAsync<AppointmentAnnotationView[]>(urlAppointmentAnnotation, q);
		}

		export function updateAsync(model?: AppointmentAnnotation) {
			return server.updateAsync<AppointmentAnnotation>(urlAppointmentAnnotation, model);
		}
	}

	export namespace companyAnnotation {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<CompanyAnnotation>(urlCompanyAnnotation, id, includes);
		}

		export function updateAsync(model?: CompanyAnnotation) {
			return server.updateAsync<CompanyAnnotation>(urlCompanyAnnotation, model);
		}

		export function listAsync(q?: CompanyAnnotationQuery | any) {
			return server.listAsync<CompanyAnnotation[]>(urlCompanyAnnotation, q);
		}

		export function viewAsync(q?: CompanyAnnotationQuery | any) {
			return server.viewAsync<CompanyAnnotationView[]>(urlCompanyAnnotation, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlCompanyAnnotation, id);
		}
	}
	export namespace file {
		export function listAsync(q?: FileQuery | any) {
			return server.listAsync<File[]>(urlFile, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlFile, id);
		}
		export function downloadByIdAsync(id: string) {
			return server.downloadByIdAsync(urlFile, id);
		}
		export function startUploadFileAsync(id: string | undefined, fileInfo: { fileName: string; expectedSize: number; entityType: string }) {
			return server.startUploadFileAsync(urlFile, id, fileInfo);
		}
		export function uploadFileAsync(fileUrl: string, fileData: object, setUploadProgress: Dispatch<SetStateAction<number>>) {
			return server.uploadFileAsync(fileUrl, fileData, setUploadProgress);
		}
		export function completeUploadAsync(url: string, uploadSuccess: boolean) {
			return server.completeUploadAsync(urlFile, url, uploadSuccess);
		}
		export function cancelUploadAsync() {
			return server.cancelUploadAsync();
		}
	}

	export namespace visitingFrequency {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<VisitingFrequency>(urlVisitingFrequency, id);
		}

		export function updateAsync(model?: VisitingFrequency) {
			return server.updateAsync<VisitingFrequency>(urlVisitingFrequency, model);
		}

		export function listAsync(q?: VisitingFrequencyQuery | any) {
			return server.listAsync<VisitingFrequency[]>(urlVisitingFrequency, q);
		}

		export function viewAsync(q?: VisitingFrequencyQuery | any) {
			return server.viewAsync<VisitingFrequencyView[]>(urlVisitingFrequency, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlVisitingFrequency, id);
		}

		//TODO: REMOVE???
		export function list() {
			return server.list(urlVisitingFrequency);
		}
	}

	export namespace companyGroup {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<CompanyGroup>(urlCompanyGroup, id);
		}

		export function updateAsync(model?: CompanyGroup) {
			return server.updateAsync<CompanyGroup>(urlCompanyGroup, model);
		}

		export function listAsync(q?: CompanyGroupQuery | any) {
			return server.listAsync<CompanyGroup[]>(urlCompanyGroup, q);
		}

		export function viewAsync(q?: CompanyGroupQuery | any) {
			return server.viewAsync<CompanyGroupView[]>(urlCompanyGroup, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlCompanyGroup, id);
		}
	}

	export namespace prioritizedCompany {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<PrioritizedCompany>(urlPrioritizedCompany, id, includes);
		}

		export function updateAsync(model?: PrioritizedCompany) {
			return server.updateAsync<PrioritizedCompany>(urlPrioritizedCompany, model);
		}

		export function listAsync(q?: PrioritizedCompanyQuery | any) {
			return server.listAsync<PrioritizedCompany[]>(urlPrioritizedCompany, q);
		}

		/** WARNING: Make sure to send both skip and take! */
		export function viewAsync(q?: PrioritizedCompanyQuery | any) {
			return server.viewAsync<PrioritizedCompanyView[]>(urlPrioritizedCompany, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlPrioritizedCompany, id);
		}
	}

	export namespace prioritizedTask {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<PrioritizedTask>(urlPrioritizedTask, id, includes);
		}

		export function updateAsync(model?: PrioritizedTask) {
			return server.updateAsync<PrioritizedTask>(urlPrioritizedTask, model);
		}

		export function listAsync(q?: PrioritizedTaskQuery | any) {
			return server.listAsync<PrioritizedTask[]>(urlPrioritizedTask, q);
		}

		/** WARNING: Make sure to send both skip and take! */
		export function viewAsync(q?: PrioritizedTaskQuery | any) {
			return server.viewAsync<PrioritizedTaskView[]>(urlPrioritizedTask, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlPrioritizedTask, id);
		}
	}

	export namespace prioritizedOpportunity {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<PrioritizedOpportunity>(urlPrioritizedOpportunity, id, includes);
		}

		export function updateAsync(model?: PrioritizedOpportunity) {
			return server.updateAsync<PrioritizedOpportunity>(urlPrioritizedOpportunity, model);
		}

		export function listAsync(q?: PrioritizedOpportunityQuery | any) {
			return server.listAsync<PrioritizedOpportunity[]>(urlPrioritizedOpportunity, q);
		}

		/** WARNING: Make sure to send both skip and take! */
		export function viewAsync(q?: PrioritizedCompanyQuery | any) {
			return server.viewAsync<PrioritizedOpportunityView[]>(urlPrioritizedOpportunity, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlPrioritizedOpportunity, id);
		}
	}

	export namespace contact {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Contact>(urlContact, id, includes);
		}

		export function updateAsync(model?: Contact) {
			return server.updateAsync<Contact>(urlContact, model);
		}

		export function listAsync(q?: ContactQuery | any) {
			return server.listAsync<Contact[]>(urlContact, q);
		}

		export function viewAsync(q?: ContactQuery | any) {
			return server.viewAsync<ContactView[]>(urlContact, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlContact, id);
		}
	}

	export namespace fieldDefinition {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<FieldDefinition>(urlFieldDefinition, id);
		}

		export function updateAsync(model?: FieldDefinition) {
			return server.updateAsync<FieldDefinition>(urlFieldDefinition, model);
		}

		export function listAsync(q?: FieldDefinitionQuery | any) {
			return server.listAsync<FieldDefinition[]>(urlFieldDefinition, q);
		}

		export function getByEntityType(entity: EntityType) {
			return server.getAsync<FieldDefinition[]>(urlFieldDefinition + `/${entity}`);
		}

		/** @deprecated (obsolete, no view) */
		export function viewAsync(q?: FieldDefinitionQuery | any) {
			return server.viewAsync<[]>(urlFieldDefinition, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlFieldDefinition, id);
		}
	}

	export namespace fieldValue {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<FieldValue>(urlFieldValue, id);
		}

		export function updateAsync(model?: FieldValue) {
			return server.updateAsync<FieldValue>(urlFieldValue, model);
		}

		export function listAsync(q?: FieldValueQuery | any) {
			return server.listAsync<FieldValue[]>(urlFieldValue, q);
		}

		/** @deprecated (obsolete, no view) */
		export function viewAsync(q?: FieldValueQuery | any) {
			return server.viewAsync<[]>(urlFieldValue, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlFieldValue, id);
		}
	}

	export namespace task {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Task>(urlTask, id, includes);
		}

		export function updateAsync(model?: Task) {
			return server.updateAsync<Task>(urlTask, model);
		}

		export function listAsync(q?: TaskQuery | any) {
			return server.listAsync<Task[]>(urlTask, q);
		}

		export function viewAsync(q?: TaskQuery | any) {
			return server.viewAsync<Task[]>(urlTask, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlTask, id);
		}
	}

	export namespace appointmentType {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<AppointmentType>(urlAppointmentType, id);
		}

		export function updateAsync(model?: AppointmentType) {
			return server.updateAsync<AppointmentType>(urlAppointmentType, model);
		}

		export function listAsync(q?: AppointmentQuery | any) {
			return server.listAsync<AppointmentType[]>(urlAppointmentType, q);
		}

		export function viewAsync(q?: AppointmentQuery | any) {
			return server.viewAsync<AppointmentTypeView[]>(urlAppointmentType, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlAppointmentType, id);
		}
	}

	export namespace country {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<Country>(urlCountry, id);
		}

		export function updateAsync(model?: Country) {
			return server.updateAsync<Country>(urlCountry, model);
		}

		export function listAsync(q?: CountryQuery | any) {
			return server.listAsync<Country[]>(urlCountry, q);
		}

		export function viewAsync(q?: CountryQuery | any) {
			return server.viewAsync<CountryView[]>(urlCountry, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlCountry, id);
		}
	}

	export namespace addressType {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<AddressType>(urlAddressType, id);
		}

		export function updateAsync(model?: AddressType) {
			return server.updateAsync<AddressType>(urlAddressType, model);
		}

		export function listAsync(q?: AddressTypeQuery | any) {
			return server.listAsync<AddressType[]>(urlAddressType, q);
		}

		export function viewAsync(q?: AddressTypeQuery | any) {
			return server.viewAsync<AddressTypeView[]>(urlAddressType, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlAddressType, id);
		}
	}

	export namespace opportunity {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Opportunity>(urlOpportunity, id, includes);
		}

		export function updateAsync(model?: Opportunity) {
			return server.updateAsync<Opportunity>(urlOpportunity, model);
		}

		export function listAsync(q?: OpportunityQuery | any) {
			return server.listAsync<Opportunity[]>(urlOpportunity, q);
		}

		/** @deprecated (obsolete, no view) */
		export function viewAsync(q?: OpportunityQuery | any) {
			return server.viewAsync<[]>(urlOpportunity, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlOpportunity, id);
		}
	}

	export namespace company {
		// export const inclGetById = ['fieldValues','primaryContact','primaryShippingAddress','primaryVisitingAddress'];

		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Company>(urlCompany, id, includes);
		}

		export function updateAsync(model?: Company) {
			return server.updateAsync<Company>(urlCompany, model);
		}

		export function listAsync(q?: CompanyQuery | any) {
			return server.listAsync<Company[]>(urlCompany, q);
		}

		export function viewAsync(q?: CompanyQuery | any) {
			return server.viewAsync<CompanyView>(urlCompany, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlCompany, id);
		}

		export function getActivitiesByIdAsync(id: string) {
			return server.getByIdAsync<Activity[]>(urlCompany, id, undefined, 'Activity');
		}

		export function getAddressesByIdAsync(id: string) {
			return server.getByIdAsync<Address[]>(urlCompany, id, undefined, 'Address');
		}

		export function getAppointmentsByIdAsync(id: string) {
			return server.getByIdAsync<Address[]>(urlCompany, id, undefined, 'Appointment');
		}

		export function getAnnotationsByIdAsync(id: string) {
			return server.getByIdAsync<CompanyAnnotation[]>(urlCompany, id, undefined, 'CompanyAnnotation');
		}

		export function getContactsByIdAsync(id: string) {
			return server.getByIdAsync<Contact[]>(urlCompany, id, undefined, 'Contact');
		}

		export function getOpportunitiesByIdAsync(id: string) {
			return server.getByIdAsync<Opportunity[]>(urlCompany, id, undefined, 'Opportunity');
		}

		export function getTasksByIdAsync(id: string) {
			return server.getByIdAsync<Task[]>(urlCompany, id, undefined, 'Task');
		}
	}

	export namespace representative {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<Representative>(urlRepresentative, id);
		}

		export function updateAsync(model?: Representative) {
			return server.updateAsync<Representative>(urlRepresentative, model);
		}

		export function listAsync(q?: RepresentativeQuery | any) {
			return server.listAsync<Representative[]>(urlRepresentative, q);
		}

		export function viewAsync(q?: RepresentativeQuery | any) {
			return server.viewAsync<RepresentativeView[]>(urlRepresentative, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlRepresentative, id);
		}
	}

	export namespace address {
		export function getByIdAsync(id: string, includes = incl) {
			return server.getByIdAsync<Address>(urlAddress, id, includes);
		}

		export function updateAsync(model?: Address) {
			return server.updateAsync<Address>(urlAddress, model);
		}

		export function listAsync(q?: AddressQuery | any) {
			return server.listAsync<Address[]>(urlAddress, q);
		}

		export function viewAsync(q?: AddressQuery | any) {
			return server.viewAsync<AddressView[]>(urlAddress, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlAddress, id);
		}
	}

	export namespace opportunityStatusReason {
		export function getByIdAsync(id: string) {
			return server.getByIdAsync<OpportunityStatusReason>(urlOpportunityStatusReason, id);
		}

		export function updateAsync(model?: OpportunityStatusReason) {
			return server.updateAsync<OpportunityStatusReason>(urlOpportunityStatusReason, model);
		}

		export function listAsync(q?: OpportunityStatusReasonQuery | any) {
			return server.listAsync<OpportunityStatusReason[]>(urlOpportunityStatusReason, q);
		}

		/** @deprecated (obsolete, no view) */
		export function viewAsync(q?: OpportunityStatusReasonQuery | any) {
			return server.viewAsync<[]>(urlOpportunityStatusReason, q);
		}

		export function deleteByIdAsync(id: string) {
			return server.deleteByIdAsync(urlOpportunityStatusReason, id);
		}
	}

	export namespace quickAnnotation {
		export function updateAsync(model?: QuickAppointmentAnnotation) {
			return server.updateAsync<QuickAppointmentAnnotation>(urlQuickAnnotation, model);
		}
	}

	export namespace organizationSettings {
		export function getCompact() {
			return server.getAsync(urlOrganizationSettings + '/compact');
		}
	}

	export module postalCodeAreas {
		export function listAsync(q?: PostalCodeAreaQuery | any) {
			return server.listAsync<PostalCodeArea[]>(urlPostalCodeArea, q);
		}
	}
}
