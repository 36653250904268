/* UNMODIFIED */
import { EntityPropertyValidationAttribute, IEntityPropertyValidationAttribute } from "./EntityPropertyValidationAttribute";

export interface IStringLengthEntityPropertyValidationAttribute extends IEntityPropertyValidationAttribute {
	maximumLength: number;
    minimumLength: number;
}

export class StringLengthEntityPropertyValidationAttribute extends EntityPropertyValidationAttribute implements IStringLengthEntityPropertyValidationAttribute {
	public maximumLength: number = 0;
    public minimumLength: number = 0;
}
