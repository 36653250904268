/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";
import { IAddress } from "./Address";
import { ICompany } from "./Company";

export interface IContact extends IModelBase {
	salutation: string | null;
    firstName: string | null;
    surnamePrefixes: string | null;
    surname: string | null;
    function: string | null;
    fullName: string | null;
    homeAddress: IAddress | null;
    homeAddress_Id: string | null;
    mobileNumber: string | null;
    phoneNumber: string | null;
    emailAddress: string | null;
    company: ICompany | null;
    company_Id: string | null;
    isActive: boolean;
}

export class Contact extends ModelBase implements IContact {
	public salutation: string | null = null;
    public firstName: string | null = null;
    public surnamePrefixes: string | null = null;
    public surname: string | null = null;
    public function: string | null = null;
    public fullName: string | null = null;
    public homeAddress: IAddress | null = null;
    public homeAddress_Id: string | null = null;
    public mobileNumber: string | null = null;
    public phoneNumber: string | null = null;
    public emailAddress: string | null = null;
    public company: ICompany | null = null;
    public company_Id: string | null = null;
    public isActive: boolean = false;
}
