/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";
import { IContact } from "./Contact";
import { IAppointment } from "./Appointment";
import { IRepresentative } from "./Representative";
import { IAddress } from "./Address";
import { ICompanyGroup } from "./CompanyGroup";
import { ICompanyAnnotation } from "./CompanyAnnotation";
import { ICompanyRepresentative } from "./CompanyRepresentative";
import { ITask } from "./Task";
import { IOpportunity } from "./Opportunity";

export interface ICompany extends IModelBase {
	name: string | null;
    description: string | null;
    emailAddress: string | null;
    phoneNumber: string | null;
    faxNumber: string | null;
    website: string | null;
    primaryContact: IContact | null;
    primaryContact_Id: string | null;
    appointments: IAppointment[] | null;
    contacts: IContact[] | null;
    primaryRepresentative: IRepresentative | null;
    primaryRepresentative_Id: string | null;
    primaryVisitingAddress: IAddress | null;
    primaryVisitingAddress_Id: string | null;
    primaryShippingAddress: IAddress | null;
    primaryShippingAddress_Id: string | null;
    companyGroup: ICompanyGroup | null;
    companyGroup_Id: string | null;
    addresses: IAddress[] | null;
    isActive: boolean;
    annotations: ICompanyAnnotation[] | null;
    representatives: ICompanyRepresentative[] | null;
    tasks: ITask[] | null;
    opportunities: IOpportunity[] | null;
}

export class Company extends ModelBase implements ICompany {
	public name: string | null = null;
    public description: string | null = null;
    public emailAddress: string | null = null;
    public phoneNumber: string | null = null;
    public faxNumber: string | null = null;
    public website: string | null = null;
    public primaryContact: IContact | null = null;
    public primaryContact_Id: string | null = null;
    public appointments: IAppointment[] | null = null;
    public contacts: IContact[] | null = null;
    public primaryRepresentative: IRepresentative | null = null;
    public primaryRepresentative_Id: string | null = null;
    public primaryVisitingAddress: IAddress | null = null;
    public primaryVisitingAddress_Id: string | null = null;
    public primaryShippingAddress: IAddress | null = null;
    public primaryShippingAddress_Id: string | null = null;
    public companyGroup: ICompanyGroup | null = null;
    public companyGroup_Id: string | null = null;
    public addresses: IAddress[] | null = null;
    public isActive: boolean = false;
    public annotations: ICompanyAnnotation[] | null = null;
    public representatives: ICompanyRepresentative[] | null = null;
    public tasks: ITask[] | null = null;
    public opportunities: IOpportunity[] | null = null;
}
