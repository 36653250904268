/* UNMODIFIED */
export interface IEntityPropertyValidationAttribute {
	$type: string | null;
    errorMessageResourceKey: string | null;
}

export class EntityPropertyValidationAttribute implements IEntityPropertyValidationAttribute {
	public $type: string | null = null;
    public errorMessageResourceKey: string | null = null;
}
