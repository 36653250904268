/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";
import { IRepresentative } from "./Representative";

export interface IActivity extends IModelBase {
	assignedTo: IRepresentative | null;
    assignedTo_Id: string | null;
    startDateTime: Date | null;
    endDateTime: Date | null;
    location: string | null;
    subject: string | null;
    description: string | null;
    isRecurrenceMaster: boolean;
    recurrenceMasterAppointment: IActivity | null;
    recurrenceMasterAppointment_Id: string | null;
    recurrencePattern: string | null;
    originalStartDateTime: Date | null;
    deletedOccurrences: string | null;
    isPrivate: boolean;
}

export class Activity extends ModelBase implements IActivity {
	public assignedTo: IRepresentative | null = null;
    public assignedTo_Id: string | null = null;
    public startDateTime: Date | null = null;
    public endDateTime: Date | null = null;
    public location: string | null = null;
    public subject: string | null = null;
    public description: string | null = null;
    public isRecurrenceMaster: boolean = false;
    public recurrenceMasterAppointment: IActivity | null = null;
    public recurrenceMasterAppointment_Id: string | null = null;
    public recurrencePattern: string | null = null;
    public originalStartDateTime: Date | null = null;
    public deletedOccurrences: string | null = null;
    public isPrivate: boolean = false;
}
