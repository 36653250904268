import EventBus from '../../utilities/EventBus';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { tryFireAsync } from '../../utilities/EventService';
import { IBaseCollectionViewModel, orderStringEnum } from './_collectionViewModel.interfaces';
import { IViewModelColStatus } from '../_viewModel.interfaces';
import { Settings } from '../../globals/settings';
import { UserManager } from '../../utilities/UserManager';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { getObjProps } from '../../utilities/utils';
import { findSortOrderObject } from '../../utilities/sortOrder';

//TODO: Wrap more function in Callbacks
export function BaseViewCollectionViewModel<T, Q extends BaseQuery>(
	instanceName: string,
	apiReader: Function,
	ignoreRepresentative_id?: boolean,
	switchModeQuery: Q = new BaseQuery() as Q,
	switchModeFunc?: (modus: number, q: Q) => void,
	pkName: string = 'id', //PrimaryKey (only used for read)
	skName: string = 'company_Id', //SearchKey
	skNeeded: boolean = true, //SearchKeyNeeded
	take: number = Settings.PAGINATION_DEFAULT_TAKE,
	defaultMode: number = 0
): IBaseCollectionViewModel<T, Q> {
	const userManager = useMemo(() => new UserManager(), []);

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [searching, setSearching] = useState<boolean>(false);
	const [filters, setFilters] = useState();
	const [filtering, setFiltering] = useState(false);
	const [apiQuery, setApiQuery] = useState<Q>(switchModeQuery);
	const [items, setItems] = useState<T[]>([]);
	const [mode, setMode] = useState<number>(defaultMode);

	const initApiQuery = useCallback(() => {
		apiQuery.skip = 0;
		apiQuery.take = take;
		if (!ignoreRepresentative_id) apiQuery['representative_Id'] = userManager.getCurrentUserId();

		if (switchModeFunc) {
			switchModeFunc(mode, apiQuery);
		}

		setApiQuery(apiQuery);
	}, [apiQuery, ignoreRepresentative_id, mode, switchModeFunc, take, userManager]);

	const doReport = useCallback(
		(success: boolean, message?: string, printMessage: boolean = false) => {
			const bus = EventBus.instance();

			const params: IViewModelColStatus = {
				success: success,
				message: message,
			};

			if (printMessage) {
				console.warn(message);
			}

			bus.dispatch(instanceName, params);

			return success;
		},
		[instanceName]
	);

	const doQuery = useCallback(
		async (q?: Q): Promise<boolean> => {
			if (q === undefined) q = apiQuery;
			setApiQuery(apiQuery);

			if (q['representative_Id'] === undefined && !ignoreRepresentative_id) return false;

			async function submit(qry: Q) {
				if (skNeeded) {
					//If SearchKey is needed, but not provided, return false
					if (qry[skName] === undefined) return false;
					if (qry[skName] === undefined || qry[skName] === null) return false;
				}

				const data: T[] | undefined = await apiReader(q);

				if (data === undefined) {
					setItems([]);
					return false;
				}

				//Paging...
				if (qry.skip && qry.skip >= take) {
					data.unshift(...items);
					setItems(data);
					count = data.length;

					if (data.length > 0) return true;
					//returns false when we cannot continue further
					return false;
				}

				setItems(data);
				count = data.length;
				return true;
			}

			let count = 0;
			const success = await tryFireAsync(submit, q);

			return doReport(success, count.toString());
		},
		[apiQuery, apiReader, doReport, ignoreRepresentative_id, items, skName, skNeeded, take]
	);

	const doRead = useCallback(
		async (id?: string): Promise<boolean> => {
			//useEffect is only called after (first) render
			if (apiQuery.skip === undefined) initApiQuery();
			apiQuery[skName] = id;

			return await doQuery(apiQuery);
		},
		[apiQuery, doQuery, initApiQuery, skName]
	);

	const toggleSortOrder = useCallback(
		(propertyName, mode: 'ADD' | 'REPLACE' = 'REPLACE') => {
			const [sortOrderObject, sortOrderIndex] = findSortOrderObject(propertyName, apiQuery.order);

			apiQuery.skip = 0;
			const apiQueryCopy = { ...apiQuery };

			if (sortOrderObject === undefined) {
				if (mode === 'REPLACE') {
					apiQueryCopy.order.splice(0, apiQuery.order.length);
				}
				apiQueryCopy.order.push(`ASC ${propertyName}`);
			} else {
				if (sortOrderObject.order === orderStringEnum.ASC) {
					apiQueryCopy.order[sortOrderIndex] = `DESC ${propertyName}`;
				} else {
					apiQueryCopy.order.splice(sortOrderIndex, 1);
				}
			}

			setApiQuery(apiQueryCopy);

			doQuery(apiQueryCopy);
		},
		[apiQuery, doQuery]
	);

	const doReadAll = useCallback(
		async (modus: number = 0): Promise<boolean> => {
			//query is not saved.
			apiQuery[skName] = null;
			apiQuery.skip = 0;
			apiQuery.take = 9999;

			if (switchModeFunc) {
				switchModeFunc(modus, apiQuery);
			}

			return await doQuery(apiQuery);
		},
		[apiQuery, doQuery, skName, switchModeFunc]
	);

	/** WARNING, the caller should "lock" the callee "thread" */
	const doPageNext = useCallback(async (): Promise<boolean> => {
		const skip = apiQuery.skip ? apiQuery.skip : 0;

		console.log('doPageNext()', items, items.length, skip, take, items.length < skip + take);

		if (items.length < skip + take) return false;

		apiQuery.skip = skip + take;
		return await doQuery(apiQuery);
	}, [apiQuery, doQuery, items.length, take]);

	const doFiltering = useCallback(
		async (filters): Promise<boolean> => {
			setFilters(filters);
			apiQuery.filters = filters;

			if (filtering) return false;
			setFiltering(true);

			while (true) {
				const currentFilters = structuredClone(apiQuery.filters);
				apiQuery.skip = 0;
				apiQuery.take = take;

				await doQuery(apiQuery);

				if (JSON.stringify(currentFilters) === JSON.stringify(apiQuery.filters)) {
					setFiltering(false);
					break;
				}
			}

			return true;
		},
		[apiQuery, doQuery, filtering]
	);

	//WARNING: not all collections support switching mode!
	const doSwitchMode = useCallback(
		async (modus: number): Promise<boolean> => {
			if (switchModeFunc === undefined) return false;

			apiQuery.skip = 0;
			switchModeFunc(modus, apiQuery);
			//console.log('SWITCHMODE ' + modus);
			//console.log(apiQuery);
			setMode(modus);

			return await doQuery(apiQuery);
		},
		[apiQuery, doQuery, switchModeFunc]
	);

	//WARNING: not all collections support search!
	const doSearch = useCallback(
		async (s: string): Promise<boolean> => {
			setSearchTerm(s);
			apiQuery['searchTerm'] = s;
			console.log(
				'start of doSearch()',
				`s: ${s} apiQuery['searchTerm']: ${apiQuery['searchTerm']} searching: ${searching}`,
				`time: ${new Date().getTime()}`
			);

			if (searching) {
				console.log(
					'searching is',
					searching,
					`s: ${s} apiQuery['searchTerm']: ${apiQuery['searchTerm']} searching: ${searching}`,
					`time: ${new Date().getTime()}`
				);
				return false;
			}
			setSearching(true);
			console.log(
				'after if(searching) return false;',
				`s: ${s} apiQuery['searchTerm']: ${apiQuery['searchTerm']} searching: ${searching}`,
				`time: ${new Date().getTime()}`
			);

			while (true) {
				const currentSearchTerm = apiQuery['searchTerm'];
				apiQuery.skip = 0;
				apiQuery.take = take;

				console.log('before doQuery', `s: ${s} searchTerm: ${apiQuery['searchTerm']}`, items, `time: ${new Date().getTime()}`);
				await doQuery(apiQuery);
				console.log(
					'after doQuery',
					s,
					items,
					`will it break? ${currentSearchTerm === apiQuery['searchTerm']} ${currentSearchTerm} ${apiQuery['searchTerm']}`,
					`time: ${new Date().getTime()}`
				);

				if (currentSearchTerm === apiQuery['searchTerm']) {
					setSearching(false);
					break;
				}
			}

			return true;
		},
		[apiQuery, doQuery, searching, take]
	);

	const doSwitchRepresentative = useCallback(async (): Promise<boolean> => {
		if (ignoreRepresentative_id || apiQuery['representative_Id'] === undefined) return false;

		const currentUserId = userManager.getCurrentUserId();

		if (currentUserId !== apiQuery['representative_Id']) {
			apiQuery.skip = 0;
			apiQuery.take = take;
			apiQuery['representative_Id'] = userManager.getCurrentUserId();

			return doQuery(apiQuery);
		}

		return false;
	}, [apiQuery, doQuery, ignoreRepresentative_id, take, userManager]);

	const getRepresentativeId = useCallback(() => {
		return userManager.getCurrentUserId();
	}, [userManager]);

	const M = useCallback((): { [P in keyof T]?: P | undefined } => {
		return getObjProps<T>();
	}, []);

	useEffect(initApiQuery, []);

	return {
		doRead,
		doReadAll,
		pkName,
		skName,
		items,
		setItems,
		mode,
		query: apiQuery,
		searchTerm,
		doQuery,
		doSearch,
		doPageNext,
		doSwitchMode,
		doFiltering,
		getProperties: M,
		toggleSortOrder,
		doSwitchRepresentative,

		//helper functions
		getRepresentativeId,
	};
}

// KEEP HERE FOR DEBUGGING PURPOSES
// function printQuery() {
// 	if (qry.isComplete === true)
// 	{
// 		if (qry.queryDelegateTasks === true) {
// 			return 'Del Closed';
// 		}
// 		return 'Closed';
// 	}
// 	else {
// 		if (qry.queryDelegateTasks === true) {
// 			return 'Del Open';
// 		}
// 		return 'Open';
// 	}
// }

// console.log('pushRefresh : ' + qry.company_Id + ' (' + printQuery() + ') ' + mode);
// console.log(qry);
