/* UNMODIFIED */
import { EntityPropertyValidationAttribute, IEntityPropertyValidationAttribute } from "./EntityPropertyValidationAttribute";

export interface IDateTimeStringEntityPropertyValidationAttribute extends IEntityPropertyValidationAttribute {
	format: string | null;
}

export class DateTimeStringEntityPropertyValidationAttribute extends EntityPropertyValidationAttribute implements IDateTimeStringEntityPropertyValidationAttribute {
	public format: string | null = null;
}
