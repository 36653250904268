import { ReactElement, SyntheticEvent, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import GridComponents, { Scroll } from '../Grid';
import { IconCustomType } from '../IconCustom/IconCustom.definitions';
import { SearchBar } from '../SearchBar/SearchBar';
import { DashboardCurrentDay } from '../DashboardCurrentDay/DashboardCurrentDay';

export interface IDashboardListProps {
	//Events
	onNew(): Promise<boolean>;
	onSearch(s: string): Promise<boolean>;
	onPageNext(): any;

	iconLeft?: IconCustomType | undefined;
	disableAddButton?: boolean;
	title: string;
	elements?: ReactElement[];
	hideSearch?: boolean;
	showCalendar?: boolean;
}

export interface IDashboardListMethods {
	spinStart: () => void;
	spinStop: () => void;
}

function DashboardList(props: IDashboardListProps, ref: React.Ref<IDashboardListMethods>): JSX.Element {
	const scrollBar = useRef<Scroll>(null);
	const searchBar = useRef<SearchBar>(null);

	const spinStart = useCallback(() => searchBar.current?.spinStart(), []);
	const spinStop = useCallback(() => searchBar.current?.spinStop(), []);

	useImperativeHandle(ref, () => ({
		spinStart,
		spinStop,
	}));

	const handle_onScroll = useCallback(
		async (e: SyntheticEvent) => {
			const scrollTop: number = e.target['scrollTop'];
			const scrollHeight: number = e.target['scrollHeight'];
			const offsetHeight: number = e.target['offsetHeight'];

			if (scrollTop + offsetHeight < scrollHeight - 1) return;

			return await props.onPageNext();
		},
		[props]
	);

	return (
		<GridComponents.Column size="fourth">
			<GridComponents.Row.Header
				iconLeft={props.iconLeft}
				content={props.title}
				iconRight={!props.disableAddButton ? IconCustomType.GEN_PLUS : undefined}
				onRightClick={props.onNew}
			/>
			{!props.hideSearch && (
				<GridComponents.Row.SubHeader>
					<SearchBar
						ref={searchBar}
						searchCallback={(s) => props.onSearch(s)}
					/>
				</GridComponents.Row.SubHeader>
			)}
			{props.showCalendar && (
				<GridComponents.Row.SubHeader>
					<DashboardCurrentDay />
				</GridComponents.Row.SubHeader>
			)}
			<GridComponents.Row.Scroll
				ref={scrollBar}
				onScrollCallback={async (e) => await handle_onScroll(e)}
			>
				{props.elements}
			</GridComponents.Row.Scroll>
		</GridComponents.Column>
	);
}

export default forwardRef(DashboardList);
