import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { IRepresentative } from '../../../models/Model/Representative';
import { selectRepresentatives, selectPostalCodeAreas, selectCompanyGroups, selectVisitingFrequencies } from '../../../store/reducers/GlobalsSlice';
import { hasModule, hasPermission, moduleNames, permissionNames } from '../../../utilities/authProvider';
import FilterDropDown, { FilterDropDownOption } from '../../FilterDropDown/FilterDropDown';
import { DropDownVariant } from '../../InputFields/DropdownInput';
import { IBoundListViewProps } from './BoundListView';
import ButtonResetFilter from '../../ButtonResetFilter/ButtonResetFilter';
import OptionalFiltersDropdown, { OptionalFiltersOption } from '../../OptionalFiltersDropdown/OptionalFiltersDropdown';
import { PostalCodeArea } from '../../../models/Model/PostalCodeArea';
import { ICompanyGroup } from '../../../models/Model/CompanyGroup';
import { useTranslation } from 'react-i18next';
import { IVisitingFrequency } from '../../../models/Model/VisitingFrequency';

const Filters = (props: IBoundListViewProps) => {
	const { t, i18n } = useTranslation();
	const companyGroups: ICompanyGroup[] = useSelector(selectCompanyGroups);
	const visitingFrequencies: IVisitingFrequency[] = useSelector(selectVisitingFrequencies);
	const representatives: IRepresentative[] = useSelector(selectRepresentatives);
	const postalCodeAreas: PostalCodeArea[] = useSelector(selectPostalCodeAreas);
	const [modeFilters, setModeFilters] = useState<any>([]);
	const [optionalFilters, setOptionalFilters] = useState<any>([]);

	const currentRepresentative = representatives.filter((rep) => rep.id === props?.cvm?.query?.representative_Id);
	const [selectedValue, setSelectedValue] = useState<Array<FilterDropDownOption> | string | any>([]);

	const getFilterTypeData = useCallback((selected: FilterDropDownOption) => {
		switch (selected?.type) {
			case 'companyTypeFilter': {
				return { $type: 'prioritizedCompanyQuery.companyTypeFilter', companyType: selected.value };
			}
			case 'companyGroupIdFilter': {
				return { $type: 'prioritizedCompanyQuery.companyGroupIdFilter', id: selected.value };
			}
			case 'representativeIdFilter': {
				return {
					$type: 'prioritizedCompanyQuery.representativeIdFilter',
					IsPrimary: false,
					id: selected.value,
				};
			}
			case 'visitingFrequencyIdFilter': {
				return {
					$type: 'prioritizedCompanyQuery.visitingFrequencyIdFilter',
					id: selected.value,
				};
			}
			case 'postalCodeAreaIdFilter': {
				return {
					$type: 'prioritizedCompanyQuery.postalCodeAreaIdFilter',
					id: selected.value,
				};
			}
			case 'assignedToIdFilter': {
				return {
					$type: 'prioritizedTaskQuery.assignedToIdFilter',
					id: selected.value,
				};
			}
			case 'createdByIdFilter': {
				return {
					$type: 'prioritizedTaskQuery.createdByIdFilter',
					id: selected.value,
				};
			}
			case 'opportunityStatusFilter': {
				return {
					$type: 'prioritizedOpportunityQuery.opportunityStatusFilter',
					status: selected.value,
				};
			}
			case 'representativeIdFilterOpportunity': {
				return {
					$type: 'prioritizedOpportunityQuery.representativeIdFilter',
					id: selected.value,
				};
			}
			case 'createdByIdFilterOpportunity': {
				return {
					$type: 'prioritizedOpportunityQuery.createdByIdFilter',
					id: selected.value,
				};
			}
			case 'isCompleteFilter': {
				return {
					$type: 'taskQuery.isCompleteFilter',
					isComplete: Number(selected.value) !== 0,
				};
			}
		}
	}, []);

	const companyGroupOptions = useMemo(
		() =>
			companyGroups.map((group) => {
				return { value: group.id, label: group.description ? `${group.description} (${group.code})` : group.code, type: 'companyGroupIdFilter' };
			}),
		[companyGroups]
	);

	const visitingFrequencyOptions = useMemo(
		() =>
			visitingFrequencies.map((vf) => {
				return { value: vf.id, label: vf.description ? `${vf.description} (${vf.code})` : vf.code, type: 'visitingFrequencyIdFilter' };
			}),
		[visitingFrequencies]
	);

	const representativesCompanyOptions = useMemo(
		() =>
			representatives.map((rep) => {
				return { value: rep.id, label: rep.fullName ? `${rep.fullName} (${rep.code})` : rep.code, type: 'representativeIdFilter' };
			}),
		[representatives]
	);

	const representativesAssignToOptions = useMemo(
		() =>
			representatives.map((rep) => {
				return { value: rep.id, label: rep.fullName ? `${rep.fullName} (${rep.code})` : rep.code, type: 'assignedToIdFilter' };
			}),
		[representatives]
	);

	const representativesCreatedByOptions = useMemo(
		() =>
			representatives.map((rep) => {
				return { value: rep.id, label: rep.fullName ? `${rep.fullName} (${rep.code})` : rep.code, type: 'createdByIdFilter' };
			}),
		[representatives]
	);

	const representativesOpportunityOptions = useMemo(
		() =>
			representatives.map((rep) => {
				return { value: rep.id, label: rep.fullName ? `${rep.fullName} (${rep.code})` : rep.code, type: 'representativeIdFilterOpportunity' };
			}),
		[representatives]
	);

	const representativesCreatedByOpportunityOptions = useMemo(
		() =>
			representatives.map((rep) => {
				return { value: rep.id, label: rep.fullName ? `${rep.fullName} (${rep.code})` : rep.code, type: 'createdByIdFilterOpportunity' };
			}),
		[representatives]
	);

	const postalCodeAreasOptions = useMemo(
		() =>
			postalCodeAreas.map((rep) => {
				return { value: rep.id, label: rep.areaName, type: 'postalCodeAreaIdFilter' };
			}),
		[postalCodeAreas]
	);

	const modeOptions = useMemo(
		() =>
			modeFilters.map((mode, i) => {
				return { value: i, label: mode, type: 'isCompleteFilter' };
			}),
		[modeFilters]
	);

	const statusOptions = useMemo(
		() => [
			{ value: 'ClosedUnknown', label: t('opportunityStatus.closedUnknown'), type: 'opportunityStatusFilter' },
			{ value: 'Open', label: t('opportunityStatus.open'), type: 'opportunityStatusFilter' },
			{ value: 'Won', label: t('opportunityStatus.won'), type: 'opportunityStatusFilter' },
			{ value: 'Lost', label: t('opportunityStatus.lost'), type: 'opportunityStatusFilter' },
		],
		[i18n.language]
	);

	const renderOptionalFiltersOptions = useCallback<() => OptionalFiltersOption[]>(() => {
		switch (props.entityType) {
			case EntityType.Company: {
				return [
					{
						label: t('postalCode'),
						value: 'postalCodeAreaIdFilter',
					},
				];
			}
			default:
				return [];
		}
	}, [props.entityType]);

	useEffect(() => {
		if (selectedValue) {
			const preparedFiltersForSending = selectedValue?.map((selected: FilterDropDownOption) => getFilterTypeData(selected));

			if (preparedFiltersForSending && props?.cvm?.filtering) {
				props?.cvm?.filtering(preparedFiltersForSending);
			}
		}
	}, [selectedValue]);

	const getTypeByEntityType = useCallback((entityType) => {
		switch (entityType) {
			case EntityType.Company: {
				return 'representativeIdFilter';
			}
			case EntityType.Task: {
				return 'assignedToIdFilter';
			}
			case EntityType.Opportunity: {
				return 'representativeIdFilterOpportunity';
			}
		}
	}, []);

	const getDefaultValues = useCallback(() => {
		if (!currentRepresentative) return [];

		const baseValue = {
			value: currentRepresentative[0]?.id,
			label: currentRepresentative[0]?.fullName,
			type: getTypeByEntityType(props.entityType),
		};

		if (props.entityType === EntityType.Opportunity) {
			return [statusOptions[1], baseValue];
		}

		return [baseValue];
	}, [currentRepresentative]);

	const handleClearFilters = useCallback(() => {
		setSelectedValue(getDefaultValues());
		setOptionalFilters([]);
	}, [setSelectedValue, setOptionalFilters, getDefaultValues]);

	useEffect(() => {
		setSelectedValue(getDefaultValues());
	}, [currentRepresentative[0], props.entityType]);

	useEffect(() => {
		if (props.entityType === EntityType.Task) {
			let modes = Object.values(props.cvm.modeEnum) as string[];

			const halfLength = Math.ceil(modes.length / 2);
			modes = modes.slice(0, halfLength - 2);
			setModeFilters(modes);
		}
	}, [props.entityType, props.cvm]);

	const showOptionalFilter = useCallback(
		(type) => {
			const findFilter = optionalFilters.find((f) => f === type);
			return findFilter ? findFilter : null;
		},
		[optionalFilters]
	);

	const renderFilters = useCallback(() => {
		switch (props.entityType) {
			case EntityType.Company:
				return (
					<Fragment>
						{hasModule(moduleNames.lead) && (
							<FilterDropDown
								variant={DropDownVariant.multiCheckboxes}
								defaultValue={props.filters}
								options={[
									{ value: 'account', label: t('account'), type: 'companyTypeFilter' },
									{ value: 'lead', label: t('lead'), type: 'companyTypeFilter' },
								]}
								hasClearOptions
								hasSelectAllOptions
								filterName={`${t('type')}`}
								filterType="companyTypeFilter"
								setSelectedValue={setSelectedValue}
								selectedValue={selectedValue}
							/>
						)}
						{hasModule(moduleNames.companyGroup) && (
							<FilterDropDown
								variant={DropDownVariant.multiCheckboxes}
								defaultValue={props.filters}
								options={companyGroupOptions}
								hasClearOptions
								hasSelectAllOptions
								filterName={`${t('companyAttributes.group')}`}
								filterType="companyGroupIdFilter"
								setSelectedValue={setSelectedValue}
								selectedValue={selectedValue}
							/>
						)}
						{hasPermission(permissionNames.ShowAllCompanies) && (
							<FilterDropDown
								variant={DropDownVariant.multiCheckboxes}
								defaultValue={props.filters}
								options={representativesCompanyOptions}
								hasClearOptions
								hasSelectAllOptions
								filterName={`${t('opportunityAttributes.representative')}`}
								filterType="representativeIdFilter"
								setSelectedValue={setSelectedValue}
								selectedValue={selectedValue}
							/>
						)}
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={visitingFrequencyOptions}
							hasClearOptions
							hasSelectAllOptions
							filterName={`${t('companyAttributes.visitingFrequency')}`}
							filterType="visitingFrequencyIdFilter"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
						/>
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={postalCodeAreasOptions}
							hasClearOptions
							hasSelectAllOptions
							filterName={`${t('postalCode')}`}
							filterType="postalCodeAreaIdFilter"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
							hideFilter={!showOptionalFilter('postalCodeAreaIdFilter')}
						/>
					</Fragment>
				);
			case EntityType.Task:
				return (
					<Fragment>
						{hasPermission(permissionNames.ShowAllTasks) && (
							<FilterDropDown
								variant={DropDownVariant.multiCheckboxes}
								defaultValue={props.filters}
								options={representativesAssignToOptions}
								hasClearOptions
								hasSelectAllOptions
								filterName={`${t('representativeAttributes.assignedTo')}`}
								filterType="assignedToIdFilter"
								setSelectedValue={setSelectedValue}
								selectedValue={selectedValue}
							/>
						)}
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={representativesCreatedByOptions}
							hasClearOptions
							hasSelectAllOptions
							filterName={`${t('representativeAttributes.createdBy')}`}
							filterType="createdByIdFilter"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
						/>
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={modeOptions}
							hasClearOptions
							hasSelectAllOptions
							filterName={`${t('representativeAttributes.status')}`}
							filterType="isCompleteFilter"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
						/>
					</Fragment>
				);
			case EntityType.Opportunity:
				return (
					<Fragment>
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={statusOptions}
							hasClearOptions
							hasSelectAllOptions
							filterName={`${t('status')}`}
							filterType="opportunityStatusFilter"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
						/>
						{hasPermission(permissionNames.ShowAllOpportunities) && (
							<FilterDropDown
								variant={DropDownVariant.multiCheckboxes}
								defaultValue={props.filters}
								options={representativesOpportunityOptions}
								hasClearOptions
								hasSelectAllOptions
								filterName={`${t('opportunityAttributes.representative')}`}
								filterType="representativeIdFilterOpportunity"
								setSelectedValue={setSelectedValue}
								selectedValue={selectedValue}
							/>
						)}
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={representativesCreatedByOpportunityOptions}
							hasClearOptions
							hasSelectAllOptions
							filterName={`${t('representativeAttributes.createdBy')}`}
							filterType="createdByIdFilterOpportunity"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
						/>
					</Fragment>
				);
			default:
				return null;
		}
	}, [
		props.entityType,
		props.filters,
		selectedValue,
		companyGroupOptions,
		representativesCompanyOptions,
		postalCodeAreasOptions,
		representativesAssignToOptions,
		representativesCreatedByOptions,
		modeOptions,
		statusOptions,
		representativesOpportunityOptions,
		representativesCreatedByOpportunityOptions,
		showOptionalFilter,
	]);

	const filtersRendered = renderFilters();

	return (
		<Fragment>
			{renderFilters()}
			{filtersRendered && filtersRendered.props?.children?.length > 0 && (
				<ButtonResetFilter
					disabled={!selectedValue || (Array.isArray(selectedValue) && selectedValue.length === 0)}
					onClick={handleClearFilters}
				>
					{t('resetFilters')}
				</ButtonResetFilter>
			)}
			{renderOptionalFiltersOptions().length > 0 && (
				<OptionalFiltersDropdown
					options={renderOptionalFiltersOptions()}
					setSelectedValue={setSelectedValue}
					selectedOptionalFiltersValue={optionalFilters}
					setSelectedOptionalFiltersValue={setOptionalFilters}
				/>
			)}
		</Fragment>
	);
};
export default Filters;
