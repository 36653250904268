/* UNMODIFIED */
import { EntityPropertyValidationAttribute, IEntityPropertyValidationAttribute } from "./EntityPropertyValidationAttribute";

export interface IRegexEntityPropertyValidationAttribute extends IEntityPropertyValidationAttribute {
	regexPattern: string | null;
}

export class RegexEntityPropertyValidationAttribute extends EntityPropertyValidationAttribute implements IRegexEntityPropertyValidationAttribute {
	public regexPattern: string | null = null;
}
