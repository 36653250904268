import { ReactElement, createElement, useCallback, useEffect } from 'react';
import { t } from 'i18next';
import { IconCustomType } from '../../IconCustom/IconCustom.definitions';
import { dateHelper } from '../../../utilities/dateHelper';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { DashboardLane } from '../DashboardLane';
import DashboardListItem, { IDashboardListItemProps } from '../DashboardListItem';
import { AgendaItemViewCollectionViewModel } from '../../../viewmodels/Collections/AgendaItemViewCollectionViewModel';
import { useAppSelector } from '../../../store/hooks';
import { colorHelper } from '../../../utilities/colorHelper';
import { openAlert } from '../../Dialog/dialogSlice';
import { useDispatch } from 'react-redux';
import { AgendaItemView } from '../../../models/BusinessObjects_View/AgendaItemView';
import { CalendarItemEntityTypes } from '../../../pages/EntityPages/Agenda/Agenda';

export function DashboardLaneActivities() {
	const agendaVM = AgendaItemViewCollectionViewModel(EntityType[EntityType.Agenda]);
	const currentRepresentative = useAppSelector((store) => store.currentRepresentative.currentRepresentative);

	const dispatch = useDispatch();

	const generateElement = useCallback((entity, clickHandler: (entity: any) => void): ReactElement => {
		const type = entity.typeOfEntity;
		const info = colorHelper.getActivityInfo(type, entity.isClosed);

		const onListItemClick = (entity: AgendaItemView) => {
			if (entity.typeOfEntity === CalendarItemEntityTypes.Activity && entity.isRecurring) {
				dispatch(
					openAlert({
						text: 'activityIsRecurringWarning',
					})
				);

				return;
			}

			clickHandler(entity);
		};

		const properties: IDashboardListItemProps = {
			id: 0,
			key: entity.entity_id,
			entity: entity,
			onListItemClick,

			icon: IconCustomType.DASH_ACTIVITY,
			iconColor: info.color,
			iconTitle: info.value,

			titleTopLeft: type === 'Appointment' ? entity.company_Name : entity.subject,
			titleMidLeft: entity.location,
			titleBotLeft: type === 'Appointment' ? entity.representative_FullName : null,

			titleTopRight: dateHelper.getTimeFormatted(entity.startDateTime),
			titleMidRight: dateHelper.getTimeFormatted(entity.endDateTime),
			titleBotRight: type === 'Appointment' ? entity.appointmentType_Name : null,
		};

		const block = createElement(DashboardListItem, properties, null);
		return block;
	}, []);

	const fetchAgenda = () => {
		const today = new Date();
		const start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0); // Start of today
		const end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999); // End of today
		agendaVM.fetch(start, end);
	};

	useEffect(() => {
		fetchAgenda();
	}, [currentRepresentative]);

	return (
		<DashboardLane
			icon={IconCustomType.GEN_ACTIVITY}
			title={t('appointments')}
			cvm={agendaVM}
			elementGeneratorFunc={generateElement}
			popupEntityType={EntityType.Agenda}
			disableAddButton={false}
			agenda={true}
			hideSearch={true}
			showCalendar={true}
		/>
	);
}
