import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { t } from 'i18next';
import { entityIconDictionary } from '../../EntityDetailPopup/entityDetailPopup.tabConfigurations';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import { File } from '../../../models/Model/File';
import { Task } from '../../../models/Model/Task';
import { Opportunity } from '../../../models/Model/Opportunity';
import { useFileViewCollectionViewModel } from '../../../viewmodels/Collections/FileViewCollectionViewModel';
import { openConfirm, openAlert } from '../../Dialog/dialogSlice';
import styles from './fileListView.module.scss';
import { FileView } from './FileView/FileView';
import getErrorMessage from './getErrorMessage';
import Modal from './Modal/Modal';

interface IFileListViewProps {
	vm: IViewModel<File | Task | Opportunity | any>;
	collectionVMPropName: string;
	id: string;
	entityType: EntityType;
}
const instanceName = 'fileListView';
export const FileListView = (props: IFileListViewProps) => {
	const dispatch = useDispatch();
	const cvm = useFileViewCollectionViewModel(instanceName, false, props.id);

	const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [file, setFile] = useState<File | any>('');
	const [dragFile, setDragFile] = useState(false);

	const getType = useCallback((modelType: string) => {
		switch (modelType) {
			case 'account':
				return 'Account';
			case 'task':
				return 'Task';
			case 'opportunity':
				return 'Opportunity';
			case 'lead':
				return 'Lead';
			case 'appointment':
				return 'Appointment';
			default:
				return '';
		}
	}, []);

	const entityTypeForUpload = useMemo(() => {
		if (props.vm && props.vm.model) {
			return getType(props.vm.model.$type);
		} else return EntityType[props.entityType];
	}, [getType, props.entityType, props.vm.model, props.vm.new]);

	const toggleDialog = useCallback(() => {
		setVisibleDialog(!visibleDialog);
	}, [visibleDialog]);

	const uploadFile = useCallback(
		async (fileInfo: File | any): Promise<void> => {
			setUploadProgress(0);
			setVisibleDialog(true);
			if (!fileInfo && !file) return;
			const response = await cvm.startUploadFile(fileInfo ? fileInfo : file.fileInfo, entityTypeForUpload, setUploadProgress);
			if (response) {
				setVisibleDialog(false);
				setFile('');
			}

			if (response?.errorName) {
				setVisibleDialog(false);
				const text = getErrorMessage(response?.errorCode, response?.errorName);

				dispatch(
					openAlert({
						title: 'anErrorOccurred',
						text: text,
						dismissButtonPosition: 'bottom',
						dismissText: 'ok',
						icon: 'warning',
					})
				);
			}
		},
		[file, cvm, entityTypeForUpload, dispatch]
	);

	const dropFile = useCallback(
		(fileInfo: any): void => {
			const fileName = fileInfo.name;
			dispatch(
				openConfirm({
					title: 'files.areYouSureUpload',
					content: (
						<div className={`${styles.fileNameIcon} ${styles.dropDialogContent}`}>
							<FontAwesomeIcon
								icon={faFile}
								size="xl"
							/>
							{fileName}
						</div>
					),
					dismissCallBack: () => {
						setDragFile(false);
					},
					confirmButtons: [
						{
							confirmText: 'yes',
							onClick: () => {
								setDragFile(false);
								uploadFile(fileInfo);
							},
						},
					],
				})
			);
		},
		[dispatch, uploadFile]
	);

	useEffect(() => {
		if (file.fileType === 'drop' && file.fileInfo !== '') {
			dropFile(file.fileInfo);
		}
	}, [file]);

	useEffect(() => {
		cvm.read(props.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.collectionVMPropName]);

	return (
		<>
			{visibleDialog && (
				<Modal
					toggleDialog={toggleDialog}
					uploadProgress={uploadProgress}
					file={file.fileInfo}
					cancelUpload={cvm.cancelUpload}
				/>
			)}
			<div className={styles.fileListView}>
				<div className={styles.listHeader}>
					<div className={styles.headerText}>
						<FontAwesomeIcon
							className={styles.headerIcon}
							icon={entityIconDictionary[EntityType.File]}
						/>
						{props.vm.entityType === 'account' ? props.vm.getProperty(props?.vm?.getProperties()?.name) : props?.vm?.model?.subject}
					</div>
					<div className={styles.uploadButton}>
						<FontAwesomeIcon
							className={`${styles.icon} ${styles.action}`}
							icon={faUpload}
							size="xl"
						/>
						<input
							type="file"
							onChange={(e) => {
								setFile({ fileInfo: e.target.files ? e.target.files[0] : [], fileType: 'upload' });
								uploadFile(e.target.files ? e.target.files[0] : []);
								e.target.value = '';
							}}
						/>
					</div>
				</div>
				<div
					className={`${styles.filesWrapper} ${dragFile && styles.changeBg}`.trim()}
					onDragOver={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setDragFile(true);
					}}
					onDrop={(e) => {
						e.preventDefault();
						setFile({ fileInfo: e.dataTransfer.files[0], fileType: 'drop' });
					}}
					onDragLeave={() => {
						setDragFile(false);
					}}
				>
					<div className={`${styles.contentWrapper} ${cvm.items.length === 0 ? styles.noItems : ''}`.trim()}>
						{cvm.items.length > 0 ? (
							cvm.items.map((item, index) => {
								return (
									<div
										key={item?.id}
										className={styles.file}
									>
										<FileView
											cvm={cvm}
											item={item}
											index={index}
											primaryRepresentativeId={props?.vm?.model?.primaryRepresentative_Id}
										/>
									</div>
								);
							})
						) : (
							<div className={styles.noDataAvailable}>{t('noDataAvailable')}</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
